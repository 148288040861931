import React from "react";
import styled, { keyframes } from "styled-components";
import { useMediaQuery, useTheme } from "@mui/material";
import { Campaign } from "@mui/icons-material";
import { useAuthStore } from "@/stores/authStore";
import { shallow } from "zustand/shallow";
import useBannerSlider from "@/hooks/fetchers/useBannerSlider";
import { useConfigStore } from "@/stores/configStore";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { LowPriority } from "@mui/icons-material";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
// Keyframes for marquee animation (loop continuously)
const marqueeAnimation = keyframes`
  0% {
    transform: translateX(0); /* Start from the original position */
  }
  100% {
    transform: translateX(-100%); /* Move full width to the left */
  }
`;

// Styled container for marquee
const MarqueeContainer = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  padding: 0px;
  display: flex;
  align-items: center;
`;

// Styled text for marquee (loop continuously)
const MarqueeContent = styled.div<{ scrollSpeed: number }>`
  display: inline-block;
  white-space: nowrap;
  padding-left: 100%; /* Start from the right side */
  animation: ${(props) => props.scrollSpeed}s linear infinite ${marqueeAnimation};
`;


// Styled icon to customize the size or margin of the icon
const Icon = styled(Campaign)`
  margin-right: 8px;
  font-size: 1.5rem;
`;
const IconText = styled(PriorityHighIcon)`
  color : red;
  margin-right: 8px;
  font-size: 1.5rem;
`;
type Props = {};

export default function Marquee({ }: Props) {
  const theme = useTheme();
  const [config] = useConfigStore((state) => [state.config], shallow);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // console.log(config);

  // Call the custom hook to fetch banners
  useBannerSlider();

  const text = `${config?.text_run || config?.agent_name} เว็บคาสิโนออนไลน์อันดับ 1 ที่มาแรงที่สุดในตอนนี้`;

  return (
    <Box sx={{ flexGrow: 1 }} >
      <Grid container spacing={0} >
        <Grid item xs={1} sx={{ textAlign: "center" }}>
          <Icon />
        </Grid>
        <Grid item xs={11} sx={{ marginTop: '-5px' }}>
          <MarqueeContainer>
            <MarqueeContent scrollSpeed={30}>
              <IconText />
              {text} &nbsp;&nbsp;&nbsp;
              <IconText />
            </MarqueeContent>
          </MarqueeContainer>
        </Grid>
      </Grid>
    </Box>
  );
}

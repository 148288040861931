import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button, alpha, Tabs, Tab, useMediaQuery } from "@mui/material";
import { Icon } from "@iconify/react";
import { useRouter } from "next/navigation";
import { palette } from "@mui/system";
import { useConfigStore } from "@/stores/configStore";
import { shallow } from "zustand/shallow";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${alpha(theme.palette.primary.main, 0.4)}`,
  borderRadius: 16,
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.getContrastText(theme.palette.secondary.main),
  "&:hover": {
    color: theme.palette.primary.main,
    background: alpha(theme.palette.primary.main, 0.15),
  },
  ...((theme.name === "preset3" ||
    theme.name === "preset6" ||
    theme.name === "preset12" ||
    theme.name === "preset13" ||
    theme.name === "preset15") && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    "&:hover, &:active, &:focus": {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      background: alpha(theme.palette.primary.main, 0.8),
    },
  }),
  ...(theme.name === "preset5" && {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    "&:hover, &:active, &:focus": {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      background: alpha(theme.palette.primary.main, 0.8),
    },
  }),
  ...((theme.name === "preset17" ||
    theme.name === "preset23" ||
    theme.name === "preset27" ||
    theme.name === "preset34") && {
    background: theme.gradient[610],
    color: theme.palette.getContrastText(theme.palette.primary.main),
    "&:hover, &:active, &:focus": {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      background: alpha(theme.palette.primary.main, 0.8),
    },
  }),
}));

type Menu = {
  key: string;
  label: string;
  icon?: string;
};

const menus: Menu[] = [
  // {
  //   key: "recommend",
  //   label: "ล็อบบี้",
  //   icon: "emojione-v1:cherries",
  // },
  {
    key: "slot",
    label: "สล็อต",
    icon: "mdi:slot-machine-outline",
  },
  {
    key: "casino",
    label: "คาสิโน",
    icon: "mdi:cards-playing",
  },
  {
    key: "sport",
    label: "กีฬา",
    icon: "mdi:football",
  },
  {
    key: "lottery",
    label: "ล็อตเตอรี่",
    icon: "mdi:ferris-wheel",
  },
  // {
  //   key: "fish",
  //   label: "ยิงปลา",
  //   icon: "logos:glitch-icon",
  // },
];

export default function GameMenu(props: any) {
  const theme = useTheme();
  const [config] = useConfigStore((state) => [state.config], shallow);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const router = useRouter();

  const handleMenuClick = (_value: any, index: number) => {
    // console.log(index)

    props.setCategory(index);

    // router.push(`/?category=${key}`);
  };

  const filterMenus = config?.agent_name === 'wisdom168'
    ? menus.filter(menu => menu.key !== 'lottery')
    : menus

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Item
            sx={{
              display: "flex",
              justifyContent: {
                xs: 'start',
                md: 'center',
                lg: 'center'
              },
            }}
          >
            <Tabs
              value={props.category}
              onChange={handleMenuClick}
              variant="scrollable"
              aria-label="game menu"
              indicatorColor={"none" as any}
              scrollButtons={false}
              // scrollButtons="auto"
              sx={{
                height: '54px',
                minHeight: '54px',
                background: theme.gradient[100],
                borderRadius: '1rem',
                alignItems: 'center'
              }}
            >
              {filterMenus.map((menu, index) => (
                <Tab
                  icon={
                    <Icon
                      id="icon"
                      icon={menu?.icon || ""}
                      width={24}
                      height={24}
                    />
                  }
                  iconPosition="start"
                  key={menu.key}
                  label={menu.label}
                  sx={{
                    color: props.category === index
                      ? theme.name === 'preset28'
                        ? '#fff'
                        : (theme.gradient && theme.gradient[920])
                          ? `${theme.gradient[920]} !important`
                          : `${theme.palette.secondary.main} !important`
                      : (theme.gradient && theme.gradient[920])
                        ? theme.gradient[920]
                        : theme.palette.primary.main,
                    background: props.category === index
                      ? theme.name === 'preset16'
                        ? theme.gradient[400]
                        : theme.name === 'preset12'
                          ? theme.palette.divider
                          : theme.name === 'preset35'
                            ? '#c51616'
                            : theme.gradient[400]
                      : theme.name === 'preset28'
                        ? '#010101'
                        : theme.name === 'preset12'
                          ? theme.palette.divider
                          : theme.name === 'preset16'
                            ? 'transparent'
                            : theme.name === 'preset35'
                              ? '#c51616'
                              : theme.gradient[400],
                    height: '44px',
                    minHeight: '44px',
                    borderRadius: '1rem',
                    margin: index === 0
                      ? '0 0 0 8px'
                      : filterMenus.length === index + 1
                        ? '0 8px 0 0'
                        : '0 8px 0 8px',

                    // borderRadius: index === 0
                    //   ? '1rem 0 0 1rem'
                    //   : menus.length === index + 1
                    //     ? '0 1rem 1rem 0'
                    //     : 'initial',
                    // ...(isMobile && {
                    //   minWidth: "60px",
                    // }),
                  }}
                />
              ))}
            </Tabs>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
